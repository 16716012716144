import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs';
import { UserService } from './user.service';
import { ApiService } from './api.service';
@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  url = environment.apiUrl;
  private _permissions: BehaviorSubject<readonly string[]> =
    new BehaviorSubject([]);

  constructor(private http: HttpClient, private userService: UserService) {
    this.loadPermissions();
  }

  getPermissionsOrgUser(orgId: number, userId: number) {
    return this.userService.getPermissions(orgId, userId);
  }

  get permissions$(): Observable<readonly string[]> {
    return this._permissions.asObservable();
  }

  getPermissionsbyUserId(userId: number): Observable<Array<string>> {
    let emptyArray: string[] = [];
    const orgId = this.userService.currentOrgObserver.value.id;
    return this.getPermissionsOrgUser(orgId, userId).pipe(
      map((userPermission: any) => {
        if(userPermission) {
          const permissions : string[] = userPermission.map((p) => p.name) as string[];
          return permissions;
        } else {
          return emptyArray;
        }
      }),
      catchError(() => {
        return of(emptyArray);
      })
    );
  }


  async loadPermissions(): Promise<void> {
    const userId = await this.userService.getCurrentUserId();
    const orgId = this.userService.getCurrentOrg();

    if(orgId == -1)
      return this._permissions.next([]);

    this.getPermissionsOrgUser(orgId, userId).subscribe({
      next: (userPermission: any) => {
        if(userPermission) {
          const permissions = userPermission.map((p) => p.name)
          this._permissions.next(permissions);
        } else {
          this._permissions.next([]);
        }
      },
      error: (err) => {
        this._permissions.next([]);
      },
    });
  }

  hasPermission(permissionName: string): Promise<boolean> {
    return new Promise((resolve,reject) => {
      let userPermission = this._permissions.getValue();

      const p = userPermission.find(
        (p) => (p = permissionName)
      );
      resolve(p != null);

    })
  }

}
