import {Injectable} from '@angular/core';
import {catchError, map, retry} from 'rxjs/operators';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {PalTask, Task} from '@core/models/pal-task';
import {PalMessage} from '@core/models/pal-message';
import {Recipient} from '@modules/profile/pages/messages/new-message/new-message.component';
import {PalTaskItem} from '@core/models/pal-task-item';
import {PalUser} from '@core/models/pal-user';
import {PalLibraryItem} from '@core/models/pal-library-item';
import {environment} from '@env';
import {PalTaskCategory} from '@core/models/pal-task-category';
import {AssetLibraryListItem} from '@app/core/interfaces/AssetLibraryListItem';
import {Media} from '@app/shared/component/Dialog';
import {InstructionSet} from '@app/core/models/pal-instruction-set';
import {PalOrganization} from '@app/core/models/pal-organization';
import {FeatureFlags} from '@app/modules/admin/feature-flags/apply-dialog/apply-feature-flags-dialog.component';
import {ExtractObservable} from '@app/shared/ts/utils';

const httpOptions = {
  headers: new HttpHeaders({}),
  withCredentials: true
};

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  url: string;

  public localObjects: {
    InstructionSet: {
      [key: string]: ExtractObservable<ReturnType<ApiService['getInstructionSet']>>
    },
  } = {
    InstructionSet: {}
  };

  constructor(private http: HttpClient) {
    this.url = environment.apiUrl;
  }

  doLogin(username, password): Observable<any> {
    return this.http.post<any>(`${this.url}/authenticate`, {user: {email: username, password: password}}, {withCredentials: true}).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          return of({success: false, message: 'Invalid email/phone number or password.'});
        }
        return of({success: false, message: error.message});
      }));
  }


  verifyToken(token): Observable<any> {
    return this.http.post<any>(`${this.url}/verify_token`, {})
      .pipe(map(user => {
        return user;
      }));
  }

  requestPasswordReset(id): Observable<any> {
    return this.http.post<any>(`${this.url}/request_reset`, {id: id});
  }

  verifyPasswordResetKey(key, user_id): Observable<any> {
    return this.http.post<any>(`${this.url}/verify_password_key`, {key: key, user_id: user_id});
  }

  resetPasswordWithKey(key, password, confirm, user_id): Observable<any> {
    return this.http.post<any>(`${this.url}/reset_password_with_key`, {user_id: user_id, key: key, password: password, confirm: confirm});
  }

  getUserRecord(): Observable<PalUser> {
    return this.http.post<any>(`${this.url}/profile/get_user_record`, {}, httpOptions);
  }

  getOrganizationRecord(org_id): Observable<PalOrganization> {
    return this.http.post<PalOrganization>(`${this.url}/organization/get_organization_record`, {org_id: org_id}, httpOptions);
  }

  getPermissions(org_id, user_id = null): Observable<any> {
    if (user_id) {
      return this.http.post<any>(`${this.url}/organization/user-org-permissions`, {org_id: org_id, user: user_id}, httpOptions);
    } else {
      return this.http.post<any>(`${this.url}/organization/user-org-permissions`, {org_id: org_id}, httpOptions);
    }
  }

  getOrganizations(): Observable<any> {
    return this.http.post<any>(`${this.url}/profile/get_my_organizations`, {}, httpOptions);
  }

  updateOrganization(org): Observable<PalUser> {
    return this.http.post<any>(`${this.url}/organization/update_organization`, {org_id: org.id, org: org}, httpOptions);
  }

  updateUserRecord(data): Observable<any> {
    return this.http.post<any>(`${this.url}/profile/update_user_record`, {data: data}, httpOptions);
  }

  getProfiles(): Observable<any[]> {
    return this.http.post<any[]>(`${this.url}/profile/get_profiles`, {}, httpOptions);
  }

  getProfile(profi_id): Observable<any> {
    return this.http.post<any>(`${this.url}/profile/get_profile`, {profile_id: profi_id}, httpOptions);
  }

  updateProfile(profi_id, data): Observable<any> {
    return this.http.post<any>(`${this.url}/profile/update_profile`, {profile_id: profi_id, data: data}, httpOptions);
  }

  deleteProfile(profi_id): Observable<any> {
    return this.http.post<any>(`${this.url}/profile/delete_profile`, {profile_id: profi_id}, httpOptions);
  }

  getTaskList(profile_id, offset = 0): Observable<PalTask[]> {
    return this.http.post<PalTask[]>(`${this.url}/profile/get_task_list`, {profile_id: profile_id, offset: offset}, httpOptions);
  }

  getTaskByUUID(uuid, payload): Observable<any> {
    return this.http.post<any>(`${this.url}/task`, {uuid, ...payload});
  }

  getMessageCount(profile_id): Observable<number> {
    return this.http.post<number>(`${this.url}/profile/get_message_count`, {profile_id: profile_id}, httpOptions);
  }

  getMessages(profile_id): Observable<PalMessage[]> {
    return this.http.post<PalMessage[]>(`${this.url}/profile/get_messages`, {profile_id: profile_id}, httpOptions);
  }

  deleteMessage(id): Observable<string> {
    return this.http.post<string>(`${this.url}/profile/delete_message`, {message_id: id}, httpOptions);
  }

  getMessageRecipients(profile_id): Observable<Recipient[]> {
    return this.http.post<Recipient[]>(`${this.url}/profile/get_message_recipients`, {profile_id: profile_id}, httpOptions);
  }

  sendMessage(message): Observable<any> {
    return this.http.post<any>(`${this.url}/profile/send_message`, {message: message}, httpOptions);
  }

  getTaskSteps(id): Observable<PalTaskItem[]> {
    return this.http.post<any>(`${this.url}/profile/get_task`, {task_id: id}, httpOptions);
  }

  markStepStarted(task_id, step_id, total_time): Observable<any> {
    return this.http.post<any>(`${this.url}/profile/mark_step_started`, {
      task_id: task_id,
      step_id: step_id,
      total_time: total_time
    }, httpOptions);
  }

  updateStepProgress(task_id, step_id, time): Observable<any> {
    return this.http.post<any>(`${this.url}/profile/set_step_progress`, {task_id: task_id, step_id: step_id, time: time}, httpOptions);
  }

  markStepCompleted(task_id, step_id): Observable<any> {
    return this.http.post<any>(`${this.url}/profile/mark_step_completed`, {task_id: task_id, step_id: step_id}, httpOptions);
  }

  starStep(task_id, step_id, stars): Observable<any> {
    return this.http.post<any>(`${this.url}/profile/star_step`, {task_id: task_id, step_id: step_id, stars: stars}, httpOptions);
  }

  starTask(task_id, stars): Observable<any> {
    return this.http.post<any>(`${this.url}/profile/star_task`, {task_id: task_id, stars: stars}, httpOptions);
  }

  taskFeedback(task_id, feedback): Observable<any> {
    return this.http.post<any>(`${this.url}/profile/task_feedback`, {task_id: task_id, feedback: feedback}, httpOptions);
  }

  singleMarkStepStarted(uuid, step_num, total_time): Observable<any> {
    return this.http.post<any>(`${this.url}/single_mark_step_started`, {
      uuid: uuid,
      step_number: step_num,
      total_time: total_time
    }).pipe(retry(5));
  }

  singleMarkStepCompleted(uuid, step_num, otp = false): Observable<any> {
    return this.http.post<any>(`${this.url}/single_mark_step_completed`, {uuid: uuid, step_number: step_num, otp: otp}).pipe(retry(5));
  }

  singleStarStep(uuid, step_num, stars): Observable<any> {
    return this.http.post<any>(`${this.url}/single_star_step`, {uuid: uuid, step_number: step_num, stars: stars});
  }

  singleUpdateStepProgress(uuid, step_id, time): Observable<any> {
    return this.http.post<any>(`${this.url}/single_update_step_progress`, {uuid: uuid, step_id: step_id, time: time}).pipe(retry(5));
  }

  singleStarTask(uuid, stars): Observable<any> {
    return this.http.post<any>(`${this.url}/single_star_task`, {uuid: uuid, stars: stars});
  }

  singleStepFeedback(uuid, step_num, feedback): Observable<any> {
    return this.http.post<any>(`${this.url}/single_step_feedback`, {uuid: uuid, step_number: step_num, feedback: feedback});
  }

  singleTaskFeedback(uuid, feedback): Observable<any> {
    return this.http.post<any>(`${this.url}/single_task_feedback`, {uuid: uuid, feedback: feedback});
  }

  singleFormFeedback(uuid, step_num, form_fields, answers, overThePhone = false): Observable<any> {
    return this.http.post<any>(`${this.url}/single_form_feedback`, {
      uuid: uuid,
      step_id: step_num,
      form_fields: form_fields,
      answers: answers,
      otp: overThePhone
    });
  }

  getOrgDashboard(org_id): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/get_dashboard`, {org_id: org_id}, httpOptions);
  }

  getOrgLibrary(org_id): Observable<any[]> {
    return this.http.post<any>(`${this.url}/organization/get_library`, {org_id: org_id}, httpOptions);
  }

  searchOrgLibrary(org_id, needle, tags: string[], search_items: string = null): Observable<PalLibraryItem[]> {
    return this.http.post<any>(`${this.url}/organization/search_library`, {
      org_id: org_id,
      needle: needle,
      tags: tags,
      search_items: search_items
    }, httpOptions);
  }

  searchForAssignableItems(org_id, needle, search_items = ''): Observable<PalLibraryItem[]> {
    return this.http.post<any>(`${this.url}/organization/search_library`, {org_id: org_id, needle: needle, search_items}, httpOptions);
  }

  getLibraryItemForAssign(org_id, type, id): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/get_library_item_for_assign`, {org_id: org_id, type: type, id: id}, httpOptions);
  }

  getOrgLibraryTags(org_id, search_only = ''): Observable<any[]> {
    return this.http.post<any>(`${this.url}/organization/get_library_tags`, {org_id: org_id, search_only: search_only}, httpOptions);
  }

  getOrgLibraryCategories(org_id, search_only = ''): Observable<any[]> {
    return this.http.post<any>(`${this.url}/organization/get_library_categories`, {org_id: org_id, search_only: search_only}, httpOptions);
  }

  searchOrgMembers(org_id, needle, show_inactive, sort_options, is_trainer, page, length): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/search_members`, {
      org_id: org_id,
      needle: needle,
      show_inactive: show_inactive,
      sort_options: sort_options,
      is_trainer: is_trainer,
      page: page,
      length: length
    }, httpOptions);
  }

  getMemberRecord(org_id, id): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/get_member_record`, {org_id: org_id, id: id}, httpOptions);
  }

  searchTargetsForAssign(org_id, needle): Observable<any[]> {
    return this.http.post<any>(`${this.url}/organization/search_targets_for_assign`, {org_id: org_id, needle: needle}, httpOptions);
  }

  searchForTemplates(org_id, needle, tags: string[]): Observable<any[]> {
    return this.http.post<any>(`${this.url}/organization/search_templates`, {org_id: org_id, needle: needle, tags: tags}, httpOptions);
  }

  getProtocolsForAssign(org_id): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/get_protocols_for_assign`, {org_id: org_id}, httpOptions);
  }

  getTemplate(org_id, template_id): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/get_template`, {org_id: org_id, template_id: template_id}, httpOptions);
  }

  getTemplateStepsList(org_id, template_id): Observable<any[]> {
    return this.http.post<any>(`${this.url}/organization/get_template_steps`, {org_id: org_id, template_id: template_id}, httpOptions);
  }

  getOrgTemplateTags(org_id): Observable<any[]> {
    return this.http.post<any>(`${this.url}/organization/get_template_tags`, {org_id: org_id}, httpOptions);
  }

  saveTemplate(org_id, template): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/save_template`, {org_id: org_id, template: template}, httpOptions);
  }

  deleteTemplate(org_id, template): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/delete_template`, {org_id: org_id, template_id: template}, httpOptions);
  }

  getForm(org_id, form_id): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/get_form`, {org_id: org_id, form_id: form_id}, httpOptions);
  }

  deleteForm(org_id, form_id): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/delete_form`, {org_id: org_id, form_id: form_id}, httpOptions);
  }

  saveForm(org_id, form, name, description, tags, category, color, form_id): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/save_form`, {
      org_id: org_id,
      form: form,
      name: name,
      description: description,
      tags: tags,
      form_id: form_id,
      category: category,
      color: color
    }, httpOptions);

  }

  getForms(org_id, org): Observable<string> {
    return this.http.post<any>(`${this.url}/organization/get_forms`, {org_id: org_id, org: org}, httpOptions);
  }

  getSurveyAnswers(orgId, userId, trainingPlanId, surveyType): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/get_form_answers`, {
      org_id: orgId,
      user_id: userId,
      training_plan_id: trainingPlanId,
      survey_type: surveyType
    }, httpOptions);
  }

  getFormAnswers(orgId, userId, trainingPlanId): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/get_form_answers`, {
      org_id: orgId,
      user_id: userId,
      training_plan_id: trainingPlanId
    }, httpOptions);
  }

  cahpsSurveyReport(orgId, startDate, endDate, protocols): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/reports/cahps_survey`, {
      org_id: orgId,
      start_date: startDate,
      end_date: endDate,
      protocols: protocols
    }, httpOptions);
  }

  fillCahpsSurveyReport(orgId, taskId): Observable<Blob> {
    return this.http.put<Blob>(`${this.url}/organization/reports/fill_cahps_survey/${taskId}`, {
      org_id: orgId,
    }, {...httpOptions, responseType: 'blob' as 'json'});
  }

  getSurveyResponses(orgId, type, startDate, endDate): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/reports/get_survey_results_display`, {
      org_id: orgId,
      start_date: startDate,
      end_date: endDate,
      survey_type: type
    }, httpOptions);
  }

  getCMSSurveyData(orgId, type, startDate, endDate): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/reports/get_cms_survey_data`, {
      org_id: orgId,
      start_date: startDate,
      end_date: endDate,
      survey_type: type
    }, httpOptions);
  }

  getVideoPreview(id, org_id): Observable<Media> {
    return this.http.post<any>(`${this.url}/organization/get_video_preview`, {org_id: org_id, video_id: id}, httpOptions);
  }

  searchForProtocols(org_id, needle, tags: string[]): Observable<any[]> {
    return this.http.post<any>(`${this.url}/organization/search_protocols`, {org_id: org_id, needle: needle, tags: tags}, httpOptions);
  }

  searchForInstructionSets(org_id, needle, tags: string[]): Observable<any[]> {
    return this.http.post<any>(`${this.url}/organization/search_instruction_sets`, {
      org_id: org_id,
      needle: needle,
      tags: tags
    }, httpOptions);
  }

  getProtocolTags(org_id): Observable<any[]> {
    return this.http.post<any>(`${this.url}/organization/get_protocol_tags`, {org_id: org_id}, httpOptions);
  }

  multiAssignTask(org_id, targets, task): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/multi_assign`, {org_id: org_id, targets: targets, task: task}, httpOptions);
  }

  saveClientTask(org_id, client_id, task_id, task, onlySecondaries = false, group_id): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/save_task`, {
      org_id: org_id,
      client_id: client_id,
      task_id: task_id,
      task: task,
      onlySecondaries: onlySecondaries,
      group_id: group_id
    }, httpOptions);
  }

  resendTask(org_id, client_id, task_id): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/resend_task`, {
      org_id: org_id,
      client_id: client_id,
      task_id: task_id
    }, httpOptions);
  }

  getClientTask(org_id, client_id, task_id): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/get_task`, {org_id: org_id, client_id: client_id, task_id: task_id}, httpOptions);
  }

  assignInstructionSet(org_id, client_id, provider, items, date_due, expires, name, secondary_contacts = null, category_id,
                       group_id): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/assign_instruction_set`, {
      org_id: org_id,
      client_id: client_id,
      provider: provider,
      steps: items,
      date_due: date_due,
      expires: expires,
      name: name,
      secondary_contacts: secondary_contacts,
      category_id: category_id,
      group_id: group_id
    }, httpOptions);
  }

  assignProtocol(org_id, client_id, protocol_id, days, provider, secondary_contacts, start_date, group_id) {
    return this.http.post<any>(`${this.url}/organization/assign_protocol`, {
      org_id: org_id,
      client_id: client_id,
      protocol_id: protocol_id,
      provider: provider,
      days: days,
      secondary_contacts: secondary_contacts,
      start_date: start_date,
      group_id: group_id
    }, httpOptions);
  }

  updateAssignedProtocolDate(assignedId, targetDate, orgId) {
    return this.http.post<any>(`${this.url}/organization/update_assigned_protocol_date`, {
      org_id: orgId,
      assigned_id: assignedId,
      target_date: targetDate
    }, httpOptions);
  }

  searchForGroups(org_id, search): Observable<any> {
    return this.http.post<any[]>(`${this.url}/organization/search_groups`, {org_id: org_id, needle: search}, httpOptions);
  }

  getGroup(org_id, group_id): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/get_group`, {org_id: org_id, group_id: group_id}, httpOptions);
  }

  saveGroup(org_id, group_id, group): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/save_group`, {org_id: org_id, group_id: group_id, group: group}, httpOptions);
  }

  deleteGroup(org_id, group_id): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/delete_group`, {org_id: org_id, group_id: group_id}, httpOptions);
  }

  getVideo(org_id, vid): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/get_video`, {org_id: org_id, video_id: vid}, httpOptions);
  }

  saveVideo(org_id, video_id, v, fileToUpload: File, newThumbnail, thumbnailTime): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('new_file', fileToUpload);
    formData.append('org_id', org_id);
    formData.append('video_id', video_id);
    formData.append('video', JSON.stringify(v));
    formData.append('has_new_thumbail', newThumbnail);
    formData.append('thumbnail_time', thumbnailTime);

    return this.http.post<any>(`${this.url}/organization/save_video`, formData, httpOptions);
  }

  deleteVideo(org_id, video_id): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/delete_video`, {org_id: org_id, video_id: video_id}, httpOptions);
  }

  uploadVideo(org_id, video_id, fileToUpload: File, cdn_name: string): Observable<any> {
    const endpoint = `${this.url}/organization/upload_video`;
    const formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    formData.append('orgId', org_id);
    formData.append('videoId', video_id);
    formData.append('fileName', cdn_name);
    return this.http.post<any>(endpoint, formData, httpOptions);
  }

  uploadThumbnail(org_id, video_id, thumb, cdn_name): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/upload_thumbnail`, {
      org_id: org_id,
      video_id: video_id,
      thumbnail: thumb,
      uuid: cdn_name
    }, httpOptions);
  }

  getImage(org_id, image_id): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/get_image`, {org_id: org_id, image_id: image_id}, httpOptions);
  }

  saveImage(org_id, image_id, i): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/save_image`, {org_id: org_id, image_id: image_id, image: i}, httpOptions);
  }

  deleteImage(org_id, image_id): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/delete_image`, {org_id: org_id, image_id: image_id}, httpOptions);
  }

  inviteUser(org_id, user, task, instruction_set, secondary_contacts): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/invite_user`,
      {org_id: org_id, user: user, task: task, instruction_set: instruction_set, secondary_contacts: secondary_contacts}, httpOptions);
  }

  getMemberInfo(org_id, user_id): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/get_member_info`, {org_id: org_id, user_id: user_id}, httpOptions);
  }

  resendInvite(org_id, user_id): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/resend_invite`, {org_id: org_id, user_id: user_id}, httpOptions);
  }

  getOrgOverviewReport(org_id, start_date, end_date): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/reports/overview`, {
      org_id: org_id,
      start_date: start_date,
      end_date: end_date
    }, httpOptions);
  }

  toggleActive(org_id, user_id): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/toggle_active`, {org_id: org_id, user_id: user_id}, httpOptions);
  }

  toggleTrainer(org_id, user_id): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/toggle_trainers`, {org_id: org_id, user_id: user_id}, httpOptions);
  }

  addMemberToGroup(org_id, user_id, group): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/add_member_to_group`, {
      org_id: org_id,
      user_id: user_id,
      group: group
    }, httpOptions);
  }

  removeMemberFromGroup(org_id, user_id, group): Observable<any> {

    return this.http.post<any>(`${this.url}/organization/remove_member_from_group`, {
      org_id: org_id,
      user_id: user_id,
      group: group
    }, httpOptions);
  }

  toggleTaskActive(org_id, user_id, task_id): Observable<any> {

    return this.http.post<any>(`${this.url}/organization/toggle_task_active`, {
      org_id: org_id,
      user_id: user_id,
      task_id: task_id
    }, httpOptions);
  }

  deleteTask(org_id, user_id, task_id): Observable<any> {

    return this.http.post<any>(`${this.url}/organization/delete_user_task`, {
      org_id: org_id,
      user_id: user_id,
      task_id: task_id
    }, httpOptions);
  }

  addNoteToMember(org_id, user_id, note): Observable<any> {

    return this.http.post<any>(`${this.url}/organization/add_note_to_member`, {org_id: org_id, user_id: user_id, note: note}, httpOptions);
  }

  deleteNote(org_id, user_id, note_id): Observable<any> {

    return this.http.post<any>(`${this.url}/organization/delete_note`, {org_id: org_id, user_id: user_id, note_id: note_id}, httpOptions);
  }

  updateHighlights(org_id, highlights, record_id): Observable<any> {

    return this.http.post<any>(`${this.url}/organization/save_highlights`, {
      org_id: org_id,
      highlights: highlights,
      record_id: record_id
    }, httpOptions);
  }

  getProtocol(org_id, protocol_id): Observable<any> {

    return this.http.post<any>(`${this.url}/organization/get_protocol`, {org_id: org_id, protocol_id: protocol_id}, httpOptions);
  }

  saveProtocol(org_id, protocol): Observable<string> {

    return this.http.post<any>(`${this.url}/organization/save_protocol`, {org_id: org_id, protocol: protocol}, httpOptions);
  }

  deleteProtocol(org_id, protocol_id): Observable<string> {

    return this.http.post<any>(`${this.url}/organization/delete_protocol`, {org_id: org_id, protocol_id: protocol_id}, httpOptions);
  }

  deleteAssignedProtocol(org_id, user_id, assigned_id): Observable<string> {

    return this.http.post<any>(`${this.url}/organization/delete_assigned_protocol`, {
      org_id: org_id,
      assigned_id: assigned_id,
      user_id: user_id
    }, httpOptions);
  }

  reportOrganizationalOverview(org_id): Observable<string> {

    return this.http.post<any>(`${this.url}/organization/report_organization_overview`, {org_id: org_id}, httpOptions);
  }

  reportTaskSummary(org_id): Observable<string> {

    return this.http.post<any>(`${this.url}/organization/report_task_summary`, {org_id: org_id}, httpOptions);
  }

  reportClientStatus(org_id): Observable<string> {

    return this.http.post<any>(`${this.url}/organization/report_client_status`, {org_id: org_id}, httpOptions);
  }

  getProvidersDepartments(org_id): Observable<string> {

    return this.http.post<any>(`${this.url}/organization/providers_departments`, {org_id: org_id}, httpOptions);
  }

  getProviders(org_id): Observable<{ id: number; name: string; }[]> {

    return this.http.post<any>(`${this.url}/organization/providers`, {org_id: org_id}, httpOptions);
  }

  getInstructionSets(org_id: number, options: object = {}): Observable<{ id: number; name: string; }[]> {

    return this.http.post<any>(`${this.url}/organization/instruction_sets`, {...options, org_id: org_id}, httpOptions);
  }

  getInstructionSet(org_id, instruction_set_id): Observable<InstructionSet> {
    return this.http.post<any>(`${this.url}/organization/instruction_sets/${instruction_set_id}`, {
      org_id: org_id,
      instruction_set_id: instruction_set_id
    }, httpOptions);
  }

  updateProvidersDepartments(org_id, data): Observable<string> {

    return this.http.put<any>(`${this.url}/organization/providers_departments`, {org_id: org_id, data: data}, httpOptions);
  }

  updateInstructionSets(org_id, sets, providers): Observable<string> {

    return this.http.put<any>(`${this.url}/organization/instruction_sets`, {org_id: org_id, sets: sets, providers: providers}, httpOptions);
  }

  saveInstructionSet(org_id, payload): Observable<string> {

    return this.http.post<any>(`${this.url}/organization/instruction_sets/save`, {...payload, org_id: org_id}, httpOptions);
  }

  deleteInstructionSet(org_id, instruction_set_id): Observable<string> {

    return this.http.post<any>(`${this.url}/organization/instruction_sets/${instruction_set_id}/delete`, {org_id: org_id}, httpOptions);
  }

  getTaskCategories(org_id: number) {
    return this.http.post<any>(`${this.url}/organization/get_organization_categories`, {org_id: org_id}, httpOptions);
  }

  saveTaskCategory(org_id: number, data: PalTaskCategory) {
    return this.http.post<any>(`${this.url}/organization/save_organization_category`, {org_id: org_id, category: data}, httpOptions);
  }

  deleteTaskCategory(org_id: number, id: number) {

    return this.http.post<any>(`${this.url}/organization/delete_organization_category`, {org_id: org_id, category_id: id}, httpOptions);
  }

  getTaskNotificationHistory(org_id: number, id: number) {

    return this.http.post<any>(`${this.url}/organization/get_task_notification_history`, {org_id: org_id, task_id: id}, httpOptions);
  }

  getAvailablePermissions() {
    return this.http.get<any>(`${this.url}/organization/user-org-permissions`, httpOptions);
  }

  getAvailablePermissionsGroups() {
    return this.http.get<any>(`${this.url}/organization/user-org-group-permissions`, httpOptions);
  }

  updatePermissions(orgId: number, userId: number, permissionId: string) {
    return this.http.put<any>(`${this.url}/organization/user-org-permissions`, {
      orgId: orgId,
      userId: userId,
      permissionId: permissionId
    }, httpOptions);
  }

  getFormAnswersReport(token: any, org_id: number, start_date: Date, end_date: Date) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + token);
    return this.http.post<any>(`${this.url}/organization/reports/form_answers`, {
      org_id: org_id,
      start_date: start_date,
      end_date: end_date
    }, httpOptions);
  }

  updateClientRecord(data, org_id): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/update_client_record`, {org_id: org_id, data: data}, httpOptions);
  }

  // admin area
  getAdminAuthSetup() {
    return this.http.get<any>(`${this.url}/admin/2fa_setup`, httpOptions);
  }

  getAdminTwoFactor() {
    return this.http.get<any>(`${this.url}/admin/2fa`, httpOptions);
  }

  validateAdminAuthCode(code) {
    return this.http.post<any>(`${this.url}/admin/2fa_setup`, {code: code}, httpOptions);
  }

  validateAdminTwoFactor(code) {
    return this.http.post<any>(`${this.url}/admin/2fa`, {code: code}, httpOptions);
  }

  validateAdminAccess(): Observable<string> {
    return this.http.get<string>(`${this.url}/admin/validate_admin_access`, httpOptions);
  }

  validateAdminAuthorization(): Observable<string> {
    return this.http.get<string>(`${this.url}/admin/validate_access`, httpOptions);
  }

  logout() {
    return this.http.post<string>(`${this.url}/logout`, httpOptions);
  }

  adminSearchMedicalFacilities(search): Observable<PalOrganization[]> {
    let params = new HttpParams();
    params = params.append('search', search);
    return this.http.get<any>(`${this.url}/admin/search_medical_facilities`, {params: params, ...httpOptions});
  }

  adminGetTasks(): Observable<Task[]> {
    return this.http.get<any>(`${this.url}/admin/tasks`, httpOptions);
  }

  adminSearchOrganizations(search): Observable<PalOrganization[]> {
    let params = new HttpParams();
    params = params.append('search', search);
    return this.http.get<any>(`${this.url}/admin/search_organizations`, {params: params, ...httpOptions});
  }

  getAssetLibraries(): Observable<AssetLibraryListItem[]> {
    return this.http.get<AssetLibraryListItem []>(`${this.url}/admin/get_asset_libraries`, httpOptions);
  }

  getAssetLibrary(id): Observable<any> {
    return this.http.get<any>(`${this.url}/admin/library/` + id, httpOptions);
  }

  saveAssetLibrary(library): Observable<any> {
    return this.http.post<any>(`${this.url}/admin/library`, library, httpOptions);
  }

  deleteAssetLibrary(library_id): Observable<any> {
    return this.http.delete<any>(`${this.url}/admin/library/` + library_id, httpOptions);
  }

  adminCreateOrgOwner(owner) {
    return this.http.post<any>(`${this.url}/admin/organization_owner`, owner, httpOptions);
  }

  adminCreateOrgManager(org_id: number, manager: PalUser) {
    return this.http.post<any>(`${this.url}/admin/organization_manager`, {manager, org_id}, httpOptions);
  }

  adminCreateOrganization(organization, ownerId) {
    return this.http.post<any>(`${this.url}/admin/organization`, {organization, ownerId}, httpOptions);
  }

  adminUpdateOrgFeatureFlags(org_id, feature_flags) {
    return this.http.put<any>(`${this.url}/admin/organization_feature_flags`, {org_id, feature_flags}, httpOptions);
  }

  adminGetFeatureFlags(): Observable<FeatureFlags[]> {
    return this.http.post<any>(`${this.url}/admin/get_feature_flags`, {}, httpOptions);
  }

  adminSetFeatureFlags(orgIds: number[], featureFlags: PalOrganization['feature_flags']): Observable<any> {
    return this.http.post<any>(`${this.url}/admin/set_feature_flags`, {orgIds, featureFlags}, httpOptions);
  }

  getDelinquentTasksForWaitingArea(orgId, protocolNames, date): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/reports/procedure_waiting_area`, {
      org_id: orgId,
      protocols: protocolNames,
      date: date
    }, httpOptions);
  }

  getSurveyDashboard(orgId, daysOut, surveyType = ''): Observable<any> {
    return this.http.post<any>(`${this.url}/organization/reports/survey_dashboard`, {
      org_id: orgId,
      survey_type: surveyType,
      days_out: daysOut
    }, httpOptions);
  }

  getInvalidPhoneNumbers(orgId: number, search: string): Observable<PalUser[]> {
    return this.http.post<any>(`${this.url}/organization/reports/invalid_phone_numbers`, {org_id: orgId, search: search}, httpOptions);
  }
}
